<template>
  <div>

    <transition name="fade">
      <div v-if="this.orderActive" class="order_details_back">

        <div style="display: flex; align-items: center; justify-content: center;" class="order_details"
          v-if="this.userRoot !== 1">

          <div
            style="font-size: 30px;width: 100%;display: flex;justify-content: flex-end;margin-bottom: 10px;font-weight: bold;}">
            <span @click="this.setOrderActive(''); statusEdit = ''">✖</span>
          </div>

          <div class="order_details_header">
            <span>
              ID
            </span>
            <span>
              {{ this.orderActive[0][0].OrderID }}
            </span>
          </div>
          <div class="order_details_header">
            <span>
              Номер Телефона
            </span>
            <span>
              {{ this.orderActive[0][0].ClientPhone }}
            </span>
          </div>


        </div>

        <div v-else class="order_details">
          <div
            style="font-size: 30px;width: 100%;display: flex;justify-content: flex-end;margin-bottom: 10px;font-weight: bold;}">
            <span @click="this.setOrderActive(''); statusEdit = ''">✖</span>
          </div>
          <div class="new_salary" v-if="newSalary">
            <div>
              Изменить зп {{ newSalaryCleanerName }}
            </div>
            <div class="order_details_header">
              <span>
                <label>
                  ЗП
                  <input style="font-size: 20px" type="number" v-model="newSalaryCleanerSalary">
                </label>
              </span>
            </div>
            <div>
              <button
                @click="saveNewSalary(this.orderActive[0][0].ID, newSalaryCleanerID, newSalaryCleanerName, newSalaryCleanerSalary)">
                Сохранить
              </button>
              <button @click="newSalary = false; newSalaryCleanerSalary = 0; newSalaryCleanerName = ''">Отмена</button>
            </div>
          </div>

          <div class="order_details_header">
            <span>
              <label>
                Адрес
                <input type="text" v-model="this.orderActive[0][0].OrderAdress">
              </label>
            </span>
            <span>
              <label>
                Дата
                <input type="date" v-model="this.orderActive[0][0].OrderDate">
              </label>
            </span>
          </div>

          <div class="order_details_header" style="align-items: center;"
            :class="{ success: this.orderActive[0][0].Operator }">

            <select v-model="this.orderActive[0][0].Operator">
              <option value="0">
                Без оператора
              </option>
              <option v-for="oper in this.operators" :value="`${oper.ID}`">{{ oper.name }}</option>
            </select>

          </div>

          <div class="order_details_header">
            <span>
              <label>
                ID
                <input type="text" v-model="this.orderActive[0][0].OrderID">
              </label>
            </span>
          </div>

          <div class="order_details_header">
            <span>
              <label>
                Имя клиента
                <input type="text" v-model="this.orderActive[0][0].ClientName">
              </label>
            </span>
            <span>
              <label>
                Телефон
                <input type="text" v-model="this.orderActive[0][0].ClientPhone">
              </label>
            </span>
          </div>

          <div class="order_details_header">
            <span>
              <label>
                Стоимость
                <input type="text" v-model="this.orderActive[0][0].OrderPrice">
              </label>
            </span>
            <span>
              <label>
                Посреднику
                <input type="text" v-model="this.orderActive[0][0].PercentageBroker">
              </label>
            </span>
          </div>

          <div class="order_details_header">
            <span>
              <label>
                Комментарий
                <textarea type="text" v-model="this.orderActive[0][0].OrderComment"></textarea>
              </label>
            </span>
          </div>

          <div class="order_details_header">
            <span>
              <label>
                Тип заказа
                <select
                  :class="{ typeOrderOne: this.orderActive[0][0].OrderType === 'Клининг', typeOrderTwo: this.orderActive[0][0].OrderType === 'Окна', typeOrderThree: this.orderActive[0][0].OrderType === 'Химчистка' }"
                  style="    width: 100%;
    border-radius: 30px;
    padding: 10px;
    text-align: center;
    font-size: 21px;" v-model="this.orderActive[0][0].OrderType">
                  <option value="Клининг">Клининг</option>
                  <option value="Окна">Окна</option>
                  <option value="Химчистка">Химчистка</option>
                </select>
              </label>
            </span>
          </div>


          <h2 style="color: #00c000;text-shadow: 0px 12px 22px lime;" v-if="statusEdit">{{ statusEdit }}</h2>
          <div style="width: 100%; margin-bottom: 10px;">
            <button @click="saveNewDataOrder()" style="width: 100%;">Сохранить данные выше</button>
          </div>


          <div class="order_details_cleaners">
            <table>
              <thead>
                <tr>
                  <th>Имя</th>
                  <th>ЗП</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(elem, index) in this.orderActive[1]">
                  <td>
                    {{ elem.Cleaner }}
                  </td>
                  <td @click="editSalaryOrder(elem.CleanerID, elem.Cleaner, elem.OrderID, elem.CleanerSalary)">
                    <button>{{ elem.CleanerSalary }}</button>
                  </td>
                </tr>
                <tr style="height: 40px; background-color: #009e0038; cursor: pointer">
                  <td>
                    <select v-model="addCleanerID">
                      <option value="0">Выбрать</option>
                      <option v-for="(elem, index) in addCleanerInOrder" :value="`${elem.ID}`">
                        {{ elem.CleanerName }}
                      </option>
                    </select>
                  </td>
                  <td colspan="2">
                    <button @click="addCleanerInOrderFunc()" :class="{ disable: addCleanerID == 0 }">Добавить</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </transition>


    <div class="alert hidden" id="alert">
      {{ messageAlert }}
    </div>
    <div v-if="isLoggedIn" class="addOrder main-info">

      <div class="flex-row-wrap">
        <h2 v-if="this.userRoot === 1">Чистый доход за</h2>
        <div class="flex-row-wrap">
          <select v-model="getIncomeMonth">
            <option value="1">Январь</option>
            <option value="2">Февраль</option>
            <option value="3">Март</option>
            <option value="4">Апрель</option>
            <option value="5">Май</option>
            <option value="6">Июнь</option>
            <option value="7">Июль</option>
            <option value="8">Август</option>
            <option value="9">Сентябрь</option>
            <option value="10">Октябрь</option>
            <option value="11">Ноябрь</option>
            <option value="12">Декабрь</option>
          </select>
          <select v-model="getIncomeYear">
            <option v-for="year in 30" :value="`${year + 2000}`">{{ year + 2000 }}</option>
          </select>
        </div>
      </div>

      <div v-if="this.userRoot === 1" class="flex-column">
        <h2 v-if="orders">{{
          format(sumInMonth({ getIncomeMonth, getIncomeYear }) - sumInMonthExpenses({ getIncomeMonth, getIncomeYear }))
        }} руб., из них:
        </h2>
        <div>
          Клининг
          <h4 v-if="orders">{{ format(sumInMonthOrdersTypeCleaning({ getIncomeMonth, getIncomeYear })) }} руб.</h4>
        </div>
        <div>
          Окна
          <h4 v-if="orders">{{ format(sumInMonthOrdersTypeWindows({ getIncomeMonth, getIncomeYear })) }} руб.</h4>
        </div>

        <div>
          Химчистка
          <h4 v-if="orders">{{ format(sumInMonthOrdersTypeHimchistka({ getIncomeMonth, getIncomeYear })) }} руб.</h4>
        </div>


        <div v-if="orders && this.userRoot === 1">
          Расходы: {{ format(sumInMonthExpenses({ getIncomeMonth, getIncomeYear })) }} руб.
        </div>

        <div>
          <h4>Доход за конкретную дату <input type="date" v-model="incomeDate"></h4>
          <h4 v-if="incomeForDay">{{ incomeForDay }} руб. <span class="small_text">(с учётом всех расходов и
              посредника)</span></h4>
          <h4 v-if="brokerForDay">Посреднику {{ brokerForDay }} руб.</h4>
        </div>
      </div>

      <div class="flex-column">
        <h4>Сумма по заказам оператора</h4>
        <div v-for="oper in this.operators" class="flex-column">
          <h4 v-if="this.userID == oper.ID || this.userRoot === 1" :style="`background-color: ${oper.color};`">
            {{ oper.name }} {{ format(sumInMonthOrdersOperator({ getIncomeMonth, getIncomeYear }, oper.ID)) }} руб.</h4>
        </div>
      </div>
    </div>

    <div v-if="isLoggedIn" class="addOrder main-info">
      <div v-if="this.userRoot == 1" class="flex-column">
        <h3>Показать операторам заказы за выбранный месяц и год для обзвона прошлых заказов
        </h3>
        <div class="flex-row-wrap">
          <select v-model="operatorsMonth">
            <option value="1">Январь</option>
            <option value="2">Февраль</option>
            <option value="3">Март</option>
            <option value="4">Апрель</option>
            <option value="5">Май</option>
            <option value="6">Июнь</option>
            <option value="7">Июль</option>
            <option value="8">Август</option>
            <option value="9">Сентябрь</option>
            <option value="10">Октябрь</option>
            <option value="11">Ноябрь</option>
            <option value="12">Декабрь</option>
          </select>
          <select v-model="operatorsYear">
            <option v-for="year in 30" :value="`${year + 2000}`">{{ year + 2000 }}</option>
          </select>
        </div>
      </div>

      <div class="filterPhoneArray" v-if="this.userRoot != 1">
        <div v-for="elem in filterByUserPhone" class="filterPhone">
          <div class="filterPhoneElem" v-if="elem[0][0].OrderDate">
            <div class="filterPhoneElemDescr">Дата</div>
            {{ elem[0][0].OrderDate }}
          </div>
          <div class="filterPhoneElem" v-if="elem[0][0].OrderDate">
            <div class="filterPhoneElemDescr">ID</div>
            {{ elem[0][0].OrderID }}
          </div>
          <div class="filterPhoneElem" v-if="elem[0][0].ClientName">
            <div class="filterPhoneElemDescr">Имя</div>
            {{ elem[0][0].ClientName }}
          </div>
          <div class="filterPhoneElem" v-if="elem[0][0].ClientPhone">
            <div class="filterPhoneElemDescr">Телефон</div>
            <a :href="`tel:${elem[0][0].ClientPhone}`">{{ elem[0][0].ClientPhone }}</a>
          </div>
          <div class="filterPhoneElem" v-if="elem[0][0].OrderAdress">
            <div class="filterPhoneElemDescr">Адрес</div>
            {{ elem[0][0].OrderAdress }}
          </div>
          <div class="filterPhoneElem" v-if="elem[0][0].OrderComment">
            <div class="filterPhoneElemDescr">Описание</div>
            {{ elem[0][0].OrderComment }}
          </div>
          <div class="filterPhoneElem" v-if="elem[0][0].OrderPrice">
            <div class="filterPhoneElemDescr">Цена</div>
            {{ elem[0][0].OrderPrice }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoggedIn">
      <div class="addOrder">
        <h2 class="headerElem">Добавить Заказ</h2>
        <div class="createOrder flex-column">

          <label class="formElemRow">
            <span>ID заказа</span>
            <input type="number" v-model="newOrder.OrderID" placeholder="ID">
          </label>

          <div @click="clickOnLinkSimilar(similarOrderPlan.ID)" class="formElemRow link_in_order"
            v-if="similarOrderPlan && !successClickOrderSimilar">
            Найден похожий заказ в планере {{ similarOrderPlan.value }}, нажмите, чтобы вставить сюда данные из планера
          </div>

          <label class="formElemRow">
            <span>Дата заказа</span>
            <input v-model="newOrder.OrderDate" id="date" type="date">
          </label>

          <label class="formElemRow">
            <span> Связан с планером (отображаются все, кроме статусов: Отказ и Закрыт)</span>
            <select v-model="newOrder.OrderLink">
              <option value="0">Нет</option>
              <option v-for="elem in plansForLink" :value="`${elem.ID}`">{{ elem.plan_ID }} - {{ elem.address }}
              </option>
            </select>
          </label>

          <label class="formElemRow">
            <span>ФИО Клиента</span>
            <input v-bind:class="{ error: ErrorClientName, success: newOrder.ClientName }" v-model="newOrder.ClientName"
              placeholder="ФИО">
          </label>

          <label class="formElemRow">
            <span>Адрес заказа</span>
            <input :class="{ success: newOrder.OrderAdress }" v-model="newOrder.OrderAdress" placeholder="Адрес">
          </label>

          <label class="formElemRow">
            <span>Номер телефона клиента</span>
            <input :class="{ success: newOrder.ClientPhone }" v-model="newOrder.ClientPhone" type="text"
              placeholder="+7">
          </label>

          <label class="formElemRow">
            <span>Сумма заказа</span>
            <input v-bind:class="{ error: ErrorOrderPrice, success: newOrder.OrderPrice }" type="number"
              v-model="newOrder.OrderPrice" placeholder="Сумма">
          </label>

          <label class="formElemRow">
            <span>Сумма посреднику</span>
            <input :class="{ success: newOrder.PercentageBroker }" v-model="newOrder.PercentageBroker" type="number"
              placeholder="Сумма посреднику">
          </label>

          <label class="formElemRow">
            <span>Комментарий</span>
            <textarea :class="{ success: newOrder.OrderComment }" v-model="newOrder.OrderComment"></textarea>
          </label>

          <label class="formElemRow">
            <span>Оператор, которому зачтётся заказ для выплаты ЗП</span>
            <select v-model="newOrder.Operator">
              <option value="0">
                Без оператора
              </option>
              <option v-for="oper in this.operators" :value="`${oper.ID}`">{{ oper.name }}</option>
            </select>
          </label>

          <label class="flex-row-wrap checkbox-size">
            <span>С нашего сайта</span>
            <input type="checkbox" v-model="newOrder.OurSite" id="site">
          </label>

          <label class="formElemRow">
            <span>Вид уборки</span>
            <select
              :class="{ typeOrderOne: newOrder.OrderType === 'Клининг', typeOrderTwo: newOrder.OrderType === 'Окна', typeOrderThree: newOrder.OrderType === 'Химчистка', }"
              v-model="newOrder.OrderType">
              <option value="Клининг">Клининг</option>
              <option value="Окна">Окна</option>
              <option value="Химчистка">Химчистка</option>
            </select>
          </label>

          <!-- <input :class="{ success: newOrder.OrderExpenses }" v-model="newOrder.OrderExpenses" type="number"
            placeholder="Сумма затрат">
          <input :class="{ success: newOrder.NameExpense }" v-model="newOrder.NameExpense"
            placeholder="Затраты (названия)"> -->

          <label class="formElemRow">
            <span>Выберите клинеров на заказе</span>
          </label>
          <div class="list_cleaners">
            <div class="list_cleaners_cleaner" v-for="cleaner in returnWithoutHIddenCleaners" :cleaner="cleaner"
              :id="`cleaner${cleaner.ID}`" @click="selectCleanerInOrder(cleaner.ID, cleaner.CleanerName)"
              :key="cleaner.ID">{{ cleaner.CleanerName }}
            </div>
          </div>
          <label class="formElemRow" v-if="newOrder.OrderCleanersID.length">
            <span>Впишите ЗП клинерам</span>
          </label>
          <div class="salary_cleaner" style="display: flex; flex-wrap: wrap;">
            <div class="salary_cleaner_element" v-for="(salary, index) in newOrder.OrderCleanersID" :salary="salary"
              :key="salary.ID">
              <span class="salary_cleaner_element_name">{{ salary }}</span>
              <input :class="{ success: newOrder.OrderCleanersSalary[index] }" type="number" required
                style="border: 1px solid green;" :placeholder="'ЗП'" v-model="newOrder.OrderCleanersSalary[index]">
            </div>
          </div>

          <!--          <div class="cleanerSelect" v-for="cleaner in cleaners" :cleaner="cleaner" :key="cleaner.ID">-->
          <!--            <input type="checkbox" :id="cleaner.ID" v-bind:value='cleaner.CleanerName'-->
          <!--                   v-model="newOrder.OrderCleanersID">-->
          <!--            <label :for="cleaner.ID">{{ cleaner.CleanerName }}</label>-->
          <!--          </div>-->

        </div>
        <button style="width: 100%; margin-top: 30px;" @click="checkAddOrder()">Добавить</button>
      </div>


      <div class="search_order">
        <div class="search_order_elem">
          <label>
            <span>Поиск по заказам</span>
            <input style="width: 100%;" type="text" placeholder="Поиск" v-model="searchOrder">
          </label>
        </div>
        <div v-if="searchArray">
          <span>Результаты поиска {{ searchOrder }}</span>
          <div class="table">
            <table>
              <thead>
                <tr>
                  <th @click="sortedBy('OrderDate')">Дата<span v-if="sortBy == 'OrderDate'"
                      :class="{ sortDown: sortType, sortUp: !sortType }"></span></th>

                  <th>
                    ID
                  </th>

                  <th @click="sortedBy('ClientName')">Клиент <span v-if="sortBy == 'ClientName'"
                      :class="{ sortDown: sortType, sortUp: !sortType }"></span>
                  </th>
                  <th>Адрес</th>
                  <th @click="sortedBy('OrderPrice')">Стоимость <span v-if="sortBy == 'OrderPrice'"
                      :class="{ sortDown: sortType, sortUp: !sortType }"></span>
                  </th>
                  <th v-if="this.userRoot === 1">ЗП Клинеров</th>
                  <th v-if="this.userRoot === 1" @click="sortedBy('PercentageBroker')">Посредник<span
                      v-if="sortBy == 'PercentageBroker'" :class="{ sortDown: sortType, sortUp: !sortType }"></span>
                  </th>
                  <th v-if="this.userRoot === 1">Телефон</th>
                  <th v-if="this.userRoot === 1" @click="sortedBy('OrderExpenses')">Расходы<span
                      v-if="sortBy == 'OrderExpenses'" :class="{ sortDown: sortType, sortUp: !sortType }"></span>
                  </th>
                  <th v-if="this.userRoot === 1">Расходник</th>
                  <th>Клинеры</th>
                  <th>Комментарий</th>
                  <th v-if="this.userRoot === 1">Сайт</th>
                </tr>
              </thead>
              <tbody>
                <OrderTableBody v-for="order in searchArray" :order="order" :key="order.ID" />
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="orders" class="table">
        <table>
          <thead>
            <tr>
              <th @click="sortedBy('OrderDate')">Дата<span v-if="sortBy == 'OrderDate'"
                  :class="{ sortDown: sortType, sortUp: !sortType }"></span></th>

              <th>
                ID
              </th>

              <th @click="sortedBy('ClientName')">Клиент <span v-if="sortBy == 'ClientName'"
                  :class="{ sortDown: sortType, sortUp: !sortType }"></span>
              </th>
              <th>Адрес</th>
              <th @click="sortedBy('OrderPrice')">Стоимость <span v-if="sortBy == 'OrderPrice'"
                  :class="{ sortDown: sortType, sortUp: !sortType }"></span>
              </th>
              <th v-if="this.userRoot === 1">ЗП Клинеров</th>
              <th v-if="this.userRoot === 1" @click="sortedBy('PercentageBroker')">Посредник<span
                  v-if="sortBy == 'PercentageBroker'" :class="{ sortDown: sortType, sortUp: !sortType }"></span>
              </th>
              <th v-if="this.userRoot === 1">Телефон</th>
              <th v-if="this.userRoot === 1" @click="sortedBy('OrderExpenses')">Расходы<span
                  v-if="sortBy == 'OrderExpenses'" :class="{ sortDown: sortType, sortUp: !sortType }"></span>
              </th>
              <th v-if="this.userRoot === 1">Расходник</th>
              <th>Клинеры</th>
              <th>Комментарий</th>
              <th v-if="this.userRoot === 1">Сайт</th>
            </tr>
          </thead>
          <tbody>
            <OrderTableBody v-for="order in filterByDateOrders" :order="order" :key="order.ID" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OrderTableBody from "../components/OrderTableBody.vue";
import axios from "axios";


const dayIncome = new Date()
dayIncome.setDate(dayIncome.getDate())
const dayIncomeFormat =
  dayIncome.getFullYear() +
  '-' +
  ('0' + (dayIncome.getMonth() + 1)).slice(-2) +
  '-' +
  ('0' + dayIncome.getDate()).slice(-2)

const date = new Date();
const todayMonth = date.getMonth() + 1;

const todayYear = date.getFullYear();

export default {
  components: {
    OrderTableBody
  },
  data() {
    return {
      showPhones: false,

      operatorsMonth: null,
      operatorsYear: null,

      plansForLink: null,
      addCleanerID: 0,
      statusEdit: '',

      newSalaryCleanerID: '0',
      newSalaryCleanerName: '0',
      newSalaryCleanerSalary: 0,
      newSalary: false,

      sortBy: 'OrderDate',
      sortType: true,
      getIncomeMonth: todayMonth,
      getIncomeYear: todayYear,
      income: '0',

      login: '',
      password: '',

      messageAlert: 'Заказ Добавлен',

      incomeDate: dayIncomeFormat,
      incomeForDay: null,
      brokerForDay: null,

      searchOrder: '',
      timerSearchOrder: '',
      searchArray: '',

      similarOrderPlan: null,
      successClickOrderSimilar: false,
    }
  },
  watch: {
    operatorsMonth(newVal, oldVal) {
      if (oldVal) {
        this.updateSetting('operatorsMonth');
      }
    },
    operatorsYear(newVal, oldVal) {
      if (oldVal) {
        this.updateSetting('operatorsYear');
      }
    },
    incomeDate(newVal) {
      this.getIncomeDay();
    },
    'newOrder.OrderID'(newVal, oldVal) {
      this.similarOrderPlan = null;
      this.successClickOrderSimilar = false;
      this.plansForLink.forEach(order => {
        if (order.plan_ID == newVal && this.newOrder.OrderLink != order.ID) {
          this.similarOrderPlan = { ID: order.ID, value: order.plan_ID }
          return
        }
      })
    },
    searchOrder(newVal) {
      clearInterval(this.timerSearchOrder);
      if (newVal) {
        this.searchArray = '';
        this.timerSearchOrder = setTimeout(() => {
          this.searhOrder(newVal);
        }, "1000");
      }

    },
  },
  methods: {
    async clickOnLinkSimilar(ID) {
      this.successClickOrderSimilar = true;
      this.newOrder.OrderLink = ID;

      this.plansForLink.forEach(order => {
        if (order.ID == ID) {
          if (!this.newOrder.OrderAdress) {
            this.newOrder.OrderAdress = order.address;
          }
          return
        }
      })

    },
    async updateSetting(param) {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'updateSetting',
              'param': param,
              'value': this[param],
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          return response.data.plans;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getPlans() {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'getPlansForOrder',
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          return response.data.plans;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getIncomeDay() {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'getIncomeDay',
              incomeDate: this.incomeDate,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data) {
          this.incomeForDay = response.data.sum;
          this.brokerForDay = response.data.posrednicy;
          this.operatorsMonth = response.data.operatorsSettings.operatorsMonth;
          this.operatorsYear = response.data.operatorsSettings.operatorsYear;
        } else {
          alert("Ничего не найдено");
        }
      } catch (e) {
        alert("Ошибка при поиске")
        console.log(e);
      }
    },
    async searhOrder(search) {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'searhOrder',
              searchOrder: search,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data) {
          this.searchArray = response.data;
        } else {
          alert("Ничего не найдено");
        }
      } catch (e) {
        alert("Ошибка при поиске")
        console.log(e);
      }
    },
    async saveNewDataOrder() {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'saveNewDataOrder',
              ID: this.orderActive[0][0].ID,
              orderDate: this.orderActive[0][0].OrderDate,
              orderLink: this.orderActive[0][0].OrderLink,
              address: this.orderActive[0][0].OrderAdress,
              clientName: this.orderActive[0][0].ClientName,
              orderID: this.orderActive[0][0].OrderID,
              operator: this.orderActive[0][0].Operator,
              phone: this.orderActive[0][0].ClientPhone,
              cost: this.orderActive[0][0].OrderPrice,
              pecBroker: this.orderActive[0][0].PercentageBroker,
              comment: this.orderActive[0][0].OrderComment,
              orderType: this.orderActive[0][0].OrderType,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          this.statusEdit = 'Успешно сохранено';
        } else {
          alert("Ошибка при сохранении данных")
        }
      } catch (e) {
        alert("Ошибка при сохранении данных")
        console.log(e);
      }
    },
    async saveNewSalary(orderID, cleanerID, cleanerName, cleanerSalary) {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'saveNewSalary',
              orderID: orderID,
              cleanerID: cleanerID,
              cleanerName: cleanerName,
              cleanerSalary: cleanerSalary,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          for (let i in this.orderActive[1]) {
            if (this.orderActive[1][i].CleanerID === cleanerID) {
              this.orderActive[1][i].CleanerSalary = cleanerSalary;
              this.newSalary = false;
              break;
            }
          }
        } else {
          alert("Ошибка при зменении зп")
        }
      } catch (e) {
        alert("Ошибка при зменении зп")
        console.log(e);
      }
    },
    editSalaryOrder(cleanerID, cleanerName, orderID, oldSalary) {
      this.newSalaryCleanerID = cleanerID;
      this.newSalaryCleanerName = cleanerName;
      this.newSalaryCleanerSalary = oldSalary;
      this.newSalary = true;
    },
    async addCleanerInOrderFunc() {
      let cleanerID = this.addCleanerID;
      let orderID = this.orderActive[0][0].ID;

      for (let i in this.cleaners) {
        if (this.cleaners[i].ID == cleanerID) {
          var cleanerName = this.cleaners[i].CleanerName;
          break;
        }
      }
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'addCleanerInOrderFunc',
              cleanerID: cleanerID,
              orderID: orderID,
              cleanerName: cleanerName,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          this.orderActive[1].push({
            ID: response.data.ID,
            OrderID: orderID,
            Cleaner: cleanerName,
            CleanerSalary: 0,
            CleanerID: cleanerID
          });
          this.addCleanerID = 0;
        } else {
          alert("Ошибка при добавлении клинера в заказа")
        }
      } catch (e) {
        alert("Ошибка при добавлении клинера в заказа")
        console.log(e);
      }
    },
    getFormatDate(date) {
      const dateMatch = new Date(date);
      dateMatch.setDate(dateMatch.getDate());
      const dateMatchFormat = ('0' + dateMatch.getDate()).slice(-2) + '-' + ('0' + (dateMatch.getMonth() + 1)).slice(-2) + '-' + dateMatch.getFullYear();
      return dateMatchFormat;
    },
    ...mapMutations({
      nuleNewOrder: 'post/nuleNewOrder',
      setOrderActive: 'post/setOrderActive',
    }),
    checkAddOrder() {
      this.arrayPlus()
        .then(res => {
          if (!res) {
            alert("Ошибка при добавлении заказа")
          } else {
            document.getElementById("alert").classList.remove("hidden");
            this.getOrders();
            this.nuleNewOrder();
            setTimeout(async () => {
              document.getElementById("alert").classList.add("hidden");
            }, 3000)
          }
        })
    },
    async selectCleanerInOrder(ID, name) {
      this.searchCleaner(this.newOrder.OrderCleanersID, name)
        .then(res => {
          if (!res) {
            this.newOrder.OrderCleanersID.push(name);
            document.getElementById("cleaner" + ID).classList.add("cleaner_selected");
          } else {
            this.newOrder.OrderCleanersID.splice(res, 1);
            document.getElementById("cleaner" + ID).classList.remove("cleaner_selected");
          }
        })

    },
    async searchCleaner(OrderCleanersID, searchName) {
      for (let i in OrderCleanersID) {
        if (OrderCleanersID[i] === searchName) {
          return i;
        }
      }
      return false;
    },
    format(a) {
      var point = "";
      //Пока удаляю, но сохраняю целую часть...
      var x = String(a).replace(/(\.|,)\d+/, function (m) {
        point = m;
        return "";
      });

      x = x.split("").reverse().join("")
        .replace(/(\d{3})/g, "$1 ")
        .split("").reverse().join("");
      //Разбил в массив, перевернул, расставил пробелы, перевернул обратно.
      return x + point;
    },
    ...mapActions({
      keyExist: 'post/keyExist',
      getOrders: 'post/getOrders',
      addOrder: 'post/addOrder',
      getCleaners: 'post/getCleaners',
      getOperators: 'post/getOperators',
      getGeneralExpenses: 'post/getGeneralExpenses',
      arrayPlus: 'post/arrayPlus',
    }),
    sumInMonthExpenses({ getIncomeMonth, getIncomeYear }) {
      if (!this.GeneralExpenses || !this.GeneralExpenses.length) {
        return 0;
      }

      return this.GeneralExpenses.reduce((total, { ExpenseDate, CostExpense }) => {
        const date = new Date(ExpenseDate);
        const expenseMonth = date.getMonth() + 1;
        const expenseYear = date.getFullYear();

        return (expenseMonth === getIncomeMonth && expenseYear === getIncomeYear)
          ? total + Number(CostExpense)
          : total;
      }, 0);
    },


    sortedBy(event) {
      const isStringField = ['ClientName', 'NameExpense', 'OrderDate'].includes(event);
      const sortOrder = this.sortType ? 1 : -1;

      this.orders.sort((a, b) => {
        const valueA = a[0][0][event];
        const valueB = b[0][0][event];

        if (isStringField) {
          return sortOrder * valueA.localeCompare(valueB);
        } else {
          return sortOrder * (valueA - valueB);
        }
      });

      this.sortType = !this.sortType;  // Toggle sort order
      this.sortBy = event;             // Track the current sorting field
    },

    sumInMonthOrdersTypeCleaning(getIncome) {
      if (!this.orders) return 0;

      const totals = this.orders.reduce((acc, val) => {
        const order = val[0][0];
        if (order.OrderType === "Клининг") {
          const date = new Date(order.OrderDate);
          const orderMonth = date.getMonth() + 1;
          const orderYear = date.getFullYear();

          if (getIncome.getIncomeMonth === orderMonth && getIncome.getIncomeYear === orderYear) {
            acc.income += Number(order.OrderPrice);
            acc.expenses += Number(order.OrderExpenses);
            acc.percentageBroker += Number(order.PercentageBroker);

            if (val[1]) {
              val[1].forEach(salary => {
                acc.cleanersSalary += Number(salary.CleanerSalary);
              });
            }
          }
        }

        return acc;
      }, { income: 0, cleanersSalary: 0, expenses: 0, percentageBroker: 0 });

      return totals.income - totals.cleanersSalary - totals.expenses - totals.percentageBroker;
    },

    sumInMonthOrdersOperator(getIncome, ID) {
      if (!this.orders) return 0;

      const totalIncome = this.orders.reduce((acc, val) => {
        const order = val[0][0];
        const date = new Date(order.OrderDate);
        const orderMonth = date.getMonth() + 1;
        const orderYear = date.getFullYear();

        // Проверяем, что заказ принадлежит оператору и соответствует месяцу и году
        if (order.Operator == ID && getIncome.getIncomeMonth == orderMonth && getIncome.getIncomeYear == orderYear) {
          acc.income += Number(order.OrderPrice);
          acc.count++;
        }

        return acc;
      }, { income: 0, count: 0 });

      return totalIncome.income;
    },

    sumInMonthOrdersTypeWindows(getIncome) {
      if (!this.orders) return 0;

      const totals = this.orders.reduce((acc, val) => {
        const order = val[0][0];
        if (order.OrderType === "Окна") {
          const date = new Date(order.OrderDate);
          const orderMonth = date.getMonth() + 1;
          const orderYear = date.getFullYear();

          if (getIncome.getIncomeMonth === orderMonth && getIncome.getIncomeYear === orderYear) {
            acc.income += Number(order.OrderPrice);
            acc.expenses += Number(order.OrderExpenses);
            acc.percentageBroker += Number(order.PercentageBroker);

            if (val[1]) {
              val[1].forEach(salary => {
                acc.cleanersSalary += Number(salary.CleanerSalary);
              });
            }
          }
        }

        return acc;
      }, { income: 0, cleanersSalary: 0, expenses: 0, percentageBroker: 0 });

      return totals.income - totals.cleanersSalary - totals.expenses - totals.percentageBroker;
    },

    sumInMonthOrdersTypeHimchistka(getIncome) {
      if (!this.orders) return 0;

      const totals = this.orders.reduce((acc, val) => {
        const order = val[0][0];
        if (order.OrderType === "Химчистка") {
          const date = new Date(order.OrderDate);
          const orderMonth = date.getMonth() + 1;
          const orderYear = date.getFullYear();

          if (getIncome.getIncomeMonth === orderMonth && getIncome.getIncomeYear === orderYear) {
            acc.income += Number(order.OrderPrice);
            acc.expenses += Number(order.OrderExpenses);
            acc.percentageBroker += Number(order.PercentageBroker);

            if (val[1]) {
              val[1].forEach(salary => {
                acc.cleanersSalary += Number(salary.CleanerSalary);
              });
            }
          }
        }

        return acc;
      }, { income: 0, cleanersSalary: 0, expenses: 0, percentageBroker: 0 });

      return totals.income - totals.cleanersSalary - totals.expenses - totals.percentageBroker;
    },


    sumInMonth(getIncome) {
      if (!this.orders) return 0;

      const result = this.orders.reduce((acc, val) => {
        const order = val[0][0];
        const date = new Date(order.OrderDate);
        const orderMonth = date.getMonth() + 1;
        const orderYear = date.getFullYear();

        // Проверяем совпадение месяца и года
        if (getIncome.getIncomeMonth == orderMonth && getIncome.getIncomeYear == orderYear) {
          acc.income += Number(order.OrderPrice);

          // Суммируем зарплаты уборщиков, если они есть
          if (val[1]) {
            val[1].forEach(salary => {
              acc.cleanersSalary += Number(salary.CleanerSalary);
            });
          }

          // Суммируем расходы и процент брокера
          acc.expenses += Number(order.OrderExpenses);
          acc.percentageBroker += Number(order.PercentageBroker);
        }

        return acc;
      }, { income: 0, cleanersSalary: 0, expenses: 0, percentageBroker: 0 });

      // Возвращаем итоговую сумму
      return result.income - result.cleanersSalary - result.expenses - result.percentageBroker;
    }
  },
  mounted() {
    this.getCleaners();
    this.getOperators();
    this.getOrders();
    this.getGeneralExpenses();
    this.getIncomeDay();
    this.getPlans()
      .then(res => {
        this.plansForLink = res;
      })
  },

  computed: {
    addCleanerInOrder() {
      return this.cleaners.filter(
        (elem) => {
          let flag = true;
          for (let i in this.orderActive[1]) {
            if (this.orderActive[1][i].CleanerID == elem.ID) {
              flag = false;
            }
          }
          return flag;
        }
      )
    },
    returnWithoutHIddenCleaners() {
      return this.sortedCleaners.filter(el => el.hidden === 0);
    },
    sortedCleaners() {
      return this.cleaners.sort((a, b) => a["CleanerName"].localeCompare(b["CleanerName"]))
      //this.gamePlayersHome.sort((a, b) => b[event].localeCompare(a[event]))
    },
    ...mapState({
      newOrder: state => state.post.newOrder,
      orderActive: state => state.post.orderActive,
      orders: state => state.post.orders,
      cleaners: state => state.post.cleaners,
      operators: state => state.post.operators,
      isLoggedIn: state => state.post.isLoggedIn,
      error: state => state.post.error,
      GeneralExpenses: state => state.post.GeneralExpenses,

      ErrorClientName: state => state.post.ErrorClientName,
      ErrorOrderPrice: state => state.post.ErrorOrderPrice,

      searchQuery: state => state.post.searchQuery,
      userRoot: state => state.post.userRoot,
      userID: state => state.post.userID,
    }),
    filterByUserPhone() {
      if (!this.operatorsYear) return [];
      if (!this.operatorsMonth) return [];

      console.log(this.operatorsMonth, this.operatorsYear)

      return this.orders.filter((el) => {

        var dateOrder = "";
        var todayMonth = "";
        var todayYear = "";

        dateOrder = el[0][0].OrderDate

        dateOrder = new Date(dateOrder);

        todayMonth = dateOrder.getMonth() + 1;
        todayYear = dateOrder.getFullYear();

        if (el[0][0].ClientPhone && (todayMonth == this.operatorsMonth && todayYear == this.operatorsYear)) {
          return el
        }
      })
    },
    filterByDateOrders() {
      var selectedMonth = this.getIncomeMonth
      var selectedYear = this.getIncomeYear
      return this.orders.filter((el) => {

        var dateOrder = "";
        var todayMonth = "";
        var todayYear = "";

        dateOrder = el[0][0].OrderDate

        dateOrder = new Date(dateOrder);

        todayMonth = dateOrder.getMonth() + 1;
        todayYear = dateOrder.getFullYear();

        if (selectedMonth == todayMonth && todayYear == selectedYear) {
          return el
        }

      })
    }
  }

}
</script>

<style scoped>
.table {
  overflow: auto;
  padding-bottom: 5px;
  margin-top: 10px;
  border-radius: 30px;
}

th {
  cursor: pointer;
  padding: 0px 5px 0px 5px;
}

th:hover {
  background: #ebebeb;
}

table {
  width: 100%;
  text-align: center;
  border-spacing: unset;
  border-radius: 10px;
  white-space: nowrap;
}

table thead {
  background: #d3d3d3;
  height: 60px;
}


.sortDown:after {
  content: "▼";
  font-size: 10px;
  color: #1E90FF;
}

.sortUp:after {
  content: "▲";
  font-size: 10px;
  color: #1E90FF;
}

.addOrder {
  border-radius: 30px;
  border: 1px solid gray;
  padding: 20px;
  margin-top: 20px;
}

.createOrder {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

input {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
  margin: 10px;
  max-height: 40px;
  font-size: 17px;
  text-align: center;
}

textarea {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  margin: 10px;
  text-align: center;
}

.select-cleaner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.select-cleaner:only-child {
  display: flex;
}

.error {
  border: 2px solid red;
}

button {
  padding: 10px;
  width: 150px;
  border-radius: 20px;
  border: none;
  background: #0072ff;
  color: white;
}

.cleanerSelect {
  border: 1px solid gray;
  padding: 5px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.cleanerSelect input {
  margin: 0;
}

.success {
  background: #c7ffc7;
  border: none !important;
  font-weight: bold;
  color: black;
}

.typeOrderOne {
  background: #6b6b6b;
  border: none;
  font-weight: bold;
  color: #ffffff;
}

.typeOrderTwo {
  background: #009bff;
  border: none;
  font-weight: bold;
  color: #ffffff;
}

.typeOrderThree {
  background: #fffa00;
  border: none;
  font-weight: bold;
  color: black;
}

.select_cleaner {
  height: 40px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.salary_cleaner input {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
}

.list_cleaners {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  white-space: nowrap;
  min-width: 140px;
  margin-bottom: 50px;
}

.list_cleaners_cleaner {
  border: 1px solid #e9e9e9;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cleaner_selected {
  background: #1b00ff;
  color: white;
  border: none;
}

.hidden {
  display: none;
}

.alert {
  transition: all .5s;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  padding: 10px 30px 10px 30px;
  background: #87ff87ad;
  border-radius: 5px;
}

.salary_cleaner_element {
  position: relative;
  width: 100%;
}

.salary_cleaner_element_name {
  position: absolute;
  top: 0px;
  left: 4px;
  border-radius: 5px 0px 0px 5px;
  padding: 5px 10px 5px 10px;
  background: #dddddd;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
}

.salary_cleaner_element input {
  text-align: end;
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
}

@media (max-width: 440px) {
  .select-cleaner {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.order_details_back {
  background-color: #0000004a;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.order_details {
  max-width: 500px;
  width: 100%;
  min-height: 300px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  position: relative;
  height: auto;
  padding: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;

}

.fade-leave-active {
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.order_details_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order_details_header span {
  width: 100%;
}

.order_details_header span:nth-of-type(1) {
  font-weight: bold;
  font-size: 15px;
}

.order_details_header span:nth-of-type(2) {
  font-size: 13px;
  color: gray;
}


.order_details input {
  padding: 3px;
  max-height: 40px;
  font-size: 13px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.order_details label {
  font-size: 12px;
  color: gray;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order_details textarea {
  width: 100%;
  margin: 0;
}

.order_details_header select {
  width: 100%;
  border-radius: 30px !important;
  padding: 2px !important;
  text-align: center !important;
  font-size: 15px !important;
  height: 30px !important;
}

.order_details_cleaners {
  width: 100%;
}

.order_details_cleaners table thead {
  height: 10px;
}

.order_details_cleaners table td {
  border: 1px solid #dbdbdbbd;
}

.order_details_cleaners table tr {
  height: 40px;
}

.order_details_cleaners button {
  width: 80%;
  padding: 2px;
  font-size: 13px;
  height: 40px;
}

button.disable {
  background-color: #9c9c9c;
  color: #a8a8a8;
}

.new_salary {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
  padding: 30px;
}

.search_order {
  margin: 20px 0;
}

.search_order_elem label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search_order_elem label span {
  font-weight: bold;
  font-size: 20px;
}

.small_text {
  font-size: 12px;
  color: gray;
}

thead th {
  position: sticky;
  top: 0;
}

.createOrder_link {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.createOrder_link select {
  height: 50px;
  font-size: 20px;
}

.link_in_order {
  color: blue;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}

.filterPhoneArray {
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: auto;
  padding: 5px;
  justify-content: flex-start;
  align-items: baseline;
}

.filterPhone {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  background: #dddddd;
  border-radius: 10px;
  padding: 12px;
  align-items: flex-start;
  flex: 1;
}

.filterPhoneElem {
  position: relative;
  padding-top: 30px;
  white-space: nowrap;
}

.filterPhoneElemDescr {
  position: absolute;
  left: 0;
  top: 0;
  opacity: .3;
  font-weight: bold;
}
</style>
