<template>
  <ModalEntry v-if="!isLoggedIn"></ModalEntry>

  <div v-if="isLoggedIn" class="app">
    <Menu></Menu>
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>
<script>
import Menu from "@/components/UI/Menu.vue";
import ModalEntry from "@/components/ModalEntry.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Menu,
    ModalEntry,
  },
  mounted() {
    if (localStorage.entry_key) {
      this.entry({ entry_key: localStorage.entry_key })
        .then(res => {
          if (!res || res.status === 'fail') throw "Ключ не подошёл";
          this.setIsLoggedIn({ isLoggedIn: true, userRoot: res.userRoot, userID: res.userID });
          localStorage.entry_key = res.entry_key
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.post.isLoggedIn,
    }),
  },
  methods: {
    ...mapMutations({
      setIsLoggedIn: 'post/setIsLoggedIn',
    }),
    ...mapActions({
      keyExist: 'post/keyExist',
      entry: 'post/entry',
    }),
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Segoe, Tahoma, Geneva, sans-serif;
}

.app {
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 80px - 41px);
  overflow: hidden;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;

}

.fade-leave-active {
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

select {
  width: auto;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}


.space-between {
  justify-content: space-between;
}

.formElemRow {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.formElemRow span {
  font-weight: bold;
}

.formElemRow select,
.formElemRow textarea,
.formElemRow input {
  margin: 0 !important;
  width: 100%;
  text-align: start !important;
  resize: vertical;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.headerElem {
  margin-bottom: 20px;
}

.checkbox-size input {
  width: 30px;
  height: 30px;
}
</style>
